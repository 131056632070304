<template>
  <div class="devicetube">
    <div class="tab">
      <span class="title">设备安装位置</span>
    </div>
    <div class="search">
      <el-input placeholder="请输入关键字" size="small" prefix-icon="el-icon-search"></el-input>
    </div>
    <div class="add">
      <el-button type="primary" size="small" @click="showAdd = true">新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border ref="table">
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="jzwdtbszb" label="地图标识坐标"></el-table-column>
        <el-table-column prop="jzwdtbsinfo" label="地图标识信息"></el-table-column>
        <el-table-column prop="jdwxxdz" label="详细地址"></el-table-column>
        <el-table-column prop="jzwdq" label="地区"></el-table-column>
        <el-table-column prop="jzwdqsj" label="地区省"></el-table-column>
        <el-table-column prop="jzwdqs" label="地区市"></el-table-column>
        <el-table-column prop="jzwdqx" label="地区区县"></el-table-column>
        <el-table-column prop="jzwdqxz" label="乡镇"></el-table-column>
        <el-table-column prop="jzwdqc" label="村"></el-table-column>
        <el-table-column prop="jzwmc" label="建筑物名称"></el-table-column>
        <el-table-column prop="lc" label="楼层"></el-table-column>
        <el-table-column prop="ssjgbh" label="所属机构编号"></el-table-column>
        <el-table-column prop="ssjgmc" label="所属机构名称"></el-table-column>
        <el-table-column prop="jdwtype" label="建筑物类型"></el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      tableData: [
        {
          date: '2020-02-27 17:20',
          name: '2020031',
          type: '1',
          value: false
        }
      ],
      showAdd: false,
      id: '',
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-121167755
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh || '',
          unioid: '',
          wxopenid: '',
          mpopenid: ''
        }
        const { data: res } = await this.$http.get('/ykdzhyyyhsbazwzs', { params: obj })
        console.log('设备安装位置列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('设备安装位置列表请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    openAddDevicetube () {
      this.showAddDevicetube = true
    },
    closeAddDevicetube () {
      this.showAddDevicetube = false
    },
    openUpdateDevicetube () {
      this.showUpdateDevicetube = true
    },
    closeUpdateDevicetube () {
      this.showUpdateDevicetube = false
    },
    openBindInfo (id) {
      this.id = id
      this.showBindInfo = true
    },
    closeBindInfo () {
      this.id = ''
      this.showBindInfo = false
    }
  }
}
</script>

<style lang="less" scoped>
.devicetube {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      text-align: center;
      padding: 0 10px;
      color: #fff;
      font-size: 22px;
      background-color: #66b1ff;
    }
  }

  .search {
    width: 206px;
    height: 38px;
    margin-top: 20px;

    .e-input {
      width: 206px;
      height: 38px;
    }

    /deep/.el-input__inner {
      font-size: 16px;
    }

    /deep/.el-input__prefix {
      font-size: 20px;
    }
  }

  .add {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    span {
      color: #0077F9;
      cursor: pointer;
    }

    //按钮文字显示
    .el-switch {
      height: 25px !important;
    }

    ::v-deep .el-switch__core {
      width: 68px !important;
      height: 24px;
      border-radius: 100px;
      border: none;
    }

    ::v-deep .el-switch__core::after {
      width: 20px;
      height: 20px;
      top: 2px;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -21px;
    }

    /*关闭时文字位置设置*/
    ::v-deep .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: 6px;
      margin-left: 0px;
      color: rgba(255, 255, 255, 0.9);

      span {
        font-size: 12px;
      }
    }

    /* 激活时另一个文字消失 */
    ::v-deep .el-switch__label.is-active {
      display: none;
    }

    /*开启时文字位置设置*/
    ::v-deep .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: 5px;
      margin-right: 0px;
      color: rgba(255, 255, 255, 0.9);

      span {
        font-size: 12px;
      }
    }

    .color {
      color: #66b1ff;
    }

  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
