<template>
  <div class="devicetube">
    <div class="tab">
      <span class="title">设备管理</span>
    </div>
    <div class="search">
      <el-input placeholder="请输入关键字" size="small" prefix-icon="el-icon-search"></el-input>
    </div>
    <div class="add">
      <!-- <el-button type="primary" size="mini" @click="openAddDevicetube">新增</el-button> -->
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border ref="table">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="bh" label="编号"></el-table-column>
        <el-table-column prop="sblx" label="设备类型">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.sblx | type }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pp" label="品牌"></el-table-column>
        <el-table-column prop="pp" label="型号"></el-table-column>
        <el-table-column prop="ks" label="科室"></el-table-column>
        <el-table-column prop="djsj" label="登记时间"></el-table-column>
        <el-table-column prop="djr" label="登记人"></el-table-column>
        <el-table-column label="状态" >
          <template slot-scope="scope">
            <div class="flex flex1">
              <el-switch v-model="scope.row.status" active-color="#01EEFD" inactive-color="#D5D5D5" active-text="启动"
                inactive-text="关闭">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="绑定设备">
          <template slot-scope="scope">
            <div class="flex flex1">
              <span class="color">已绑定&nbsp;</span>
              <el-button type="primary" size="mini" @click="openBindInfo(scope.row.id)">查看</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260">
          <!-- slot-scope="scope" -->
          <template>
            <!-- <div class="flex">
              <span @click="openUpdateDevicetube">编辑</span>
              <span>删除</span>
            </div> -->
            <el-button size="mini" type="primary" @click="showPosition = true">位置</el-button>
            <el-button size="mini" type="primary" @click="openUpdateDevicetube">编辑</el-button>
            <el-button size="mini" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
    </div>
    <!-- 新增 -->
    <AddDevicetube v-if="showAddDevicetube" @close="closeAddDevicetube"></AddDevicetube>
    <!-- 编辑 -->
    <UpdateDevicetube v-if="showUpdateDevicetube" @close="closeUpdateDevicetube"></UpdateDevicetube>
    <!-- 绑定信息 -->
    <BindInfo v-if="showBindInfo" :id="id" @close="closeBindInfo"></BindInfo>
    <!-- 位置 -->
    <Position v-if="showPosition"></Position>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddDevicetube from './addDevicetube.vue'
import UpdateDevicetube from './updateDevicetube.vue'
import BindInfo from './bindInfo.vue'
import Position from './position.vue'
export default {
  components: {
    AddDevicetube,
    UpdateDevicetube,
    BindInfo,
    Position
  },
  data () {
    return {
      tableData: [
        {
          date: '2020-02-27 17:20',
          name: '2020031',
          type: '1',
          value: false
        }
      ],
      showAddDevicetube: false,
      showUpdateDevicetube: false,
      showBindInfo: false,
      showPosition: false,
      id: '',
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  filters: {
    type (val) {
      const obj = {
        '01': '血压',
        '02': '血糖',
        '03': '定位',
        '04': '安防',
        '05': '健康监测',
        '06': '辅助监测'
      }
      return obj[val]
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591378
      try {
        // const start = this.query1.pageNum === 1 ? '10' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.get('/ykdsbgls', { params: obj })
        console.log('设备管理列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        // this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('设备管理列表请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    openAddDevicetube () {
      this.showAddDevicetube = true
    },
    closeAddDevicetube () {
      this.showAddDevicetube = false
    },
    openUpdateDevicetube () {
      this.showUpdateDevicetube = true
    },
    closeUpdateDevicetube () {
      this.showUpdateDevicetube = false
    },
    openBindInfo (id) {
      this.id = id
      this.showBindInfo = true
    },
    closeBindInfo () {
      this.id = ''
      this.showBindInfo = false
    }
  }
}
</script>

<style lang="less" scoped>
.devicetube {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title {
      width: 105px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #66b1ff;
    }
  }

  .search {
    width: 206px;
    height: 38px;
    margin-top: 20px;

    .e-input {
      width: 206px;
      height: 38px;
    }

    /deep/.el-input__inner {
      font-size: 16px;
    }

    /deep/.el-input__prefix {
      font-size: 20px;
    }
  }

  .add {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    span {
      color: #0077F9;
      cursor: pointer;
    }

    //按钮文字显示
    .el-switch {
      height: 25px !important;
    }

    ::v-deep .el-switch__core {
      width: 68px !important;
      height: 24px;
      border-radius: 100px;
      border: none;
    }

    ::v-deep .el-switch__core::after {
      width: 20px;
      height: 20px;
      top: 2px;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -21px;
    }

    /*关闭时文字位置设置*/
    ::v-deep .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: 6px;
      margin-left: 0px;
      color: rgba(255, 255, 255, 0.9);

      span {
        font-size: 12px;
      }
    }

    /* 激活时另一个文字消失 */
    ::v-deep .el-switch__label.is-active {
      display: none;
    }

    /*开启时文字位置设置*/
    ::v-deep .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: 5px;
      margin-right: 0px;
      color: rgba(255, 255, 255, 0.9);

      span {
        font-size: 12px;
      }
    }

    .color {
      color: #66b1ff;
    }

  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
