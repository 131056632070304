<template>
  <div class="write">
    <div class="title">
      <span>绑定信息</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span>姓名：李明明</span>
        </div>
        <div class="item">
          <span>身份证号：130***********0888</span>
        </div>
        <div class="item">
          <span>是否绑定微信：是</span>
        </div>
        <div class="item">
          <span>绑定机构：某某某机构</span>
        </div>
        <div class="item item1">
          <span>绑定时间：2022-08-03  18:00</span>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1',
      params: {
        id: ''
      },
      info: {
        id: 0,
        bh: '',
        sblx: '',
        pp: '',
        lx: '',
        ks: '',
        djsj: '',
        djr: '',
        status: '',
        bdsb: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        sbglid: '',
        tjjgmc: '',
        tjjgbm: '',
        tjjgcmhid: '',
        tjjgcmhmc: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591380
      try {
        this.params.id = this.id
        console.log('请求参数', this.params)
        const { data: res } = await this.$http.post('/zhyyapi/sbglcha/' + this.id)
        console.log('设备管理根据id获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('设备管理根据id获取失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 4rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .12rem;
      right: .3rem;
      color: #fff;
      font-size: .25rem;
      cursor: pointer;
    }

    .content-title {
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .225rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .2rem;
    }

    .item1 {
      width: 66.5%;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .575rem;
      margin: 1.25rem auto 0;

      .el-button {
        height: .375rem;
        line-height: .375rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-checkbox,
  .el-radio {
    color: #fff;
  }

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
